import type { ICollections, ISeries } from '~/types/movies';
import type { RouteLocationRaw } from 'vue-router';
import { pluralize } from '~/helpers/processing';

export const useCollections = (collections: ICollections) => {
  const route = computed((): RouteLocationRaw => ({ name: 'collections-slug', params: { slug: collections?.slug } }));
  return {
    data: collections,
    count: collections?.torrent_count,
    countType: pluralize(collections?.torrent_count, ['Фильм', 'Фильма', 'Фильмов']),
    image: collections?.poster,
    name: collections?.label,

    text: collections?.description,
    route,
  };
};
